import React from "react"
import { graphql } from "gatsby"
import { Image } from "@atoms/Image"
// import Prism from '@atoms/Prism'
import SEO from "@atoms/Seo"
import * as Spacer from "@atoms/Spacer"
import { Section, SectionHeader, SectionContent, SectionColumn, SectionMoreLink } from "@layouts/Section"

import { Router } from "@reach/router"
import PrivateRoute from "@projects/PrivateRoute"
import EmbedLawson from "@blocks/EmbedLawson"
import EmbedTicket from "@blocks/EmbedTicket"

import * as css from "@css/layouts/l-article.module.styl"

// import { gsap } from "gsap"
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

const Content = ({ title, date, content, thumbnail, acf_meet }) => {
    const { info, status } = acf_meet
    const statusVal = status.split(":")[0]
    return(
        <article className={css.lArticle}>
            <div className={css.lArticle__inner}>
                <header className={css.lArticle__header}>
                    {/* <p className={css.aDate}><time dateTime={date}>{date}</time></p> */}
                    <h1 className={css.aTitle}>
                        {title}
                    </h1>
                </header>
                {thumbnail &&
                    <figure className={css.lArticle__thumbnail}>
                        <Image data={thumbnail.node.localFile} alt="thubmanil" />
                    </figure>
                }
                <div className={css.lArticle__content}>
                    <section className={css.lArticle__content__info}>
                        <div className={css.aRowFlex}>
                            <h2 className={css.aLabel}>受付状況</h2>
                            {statusVal === 'entry' &&
                                <span className={css.aTagEntry}>申込受付中</span>
                            }
                            {statusVal === 'payment' &&
                                <span className={css.aTagPayment}>入金期間中</span>
                            }
                            {statusVal === 'end' &&
                                <span className={css.aTagClose}>受付終了</span>
                            }
                            {statusVal === 'announce' &&
                                <span className={css.aTagAnnounce}>申込受付開始前</span>
                            }
                            {statusVal === 'lottery' &&
                                <span className={css.aTagLottery}>抽選中</span>
                            }
                            {statusVal === 'free' &&
                                <span className={css.aTagFree}>無料配信イベント</span>
                            }
                        </div>
                        <h2>会場・日程</h2>
                        <div dangerouslySetInnerHTML={{ __html: info }} />
                    </section>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
                {/* <iframe src="" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> */}
            </div>
        </article>
    )
}

function MeetPage({data})
{
    console.log(data)
    const { meet } = data
    const { title, date, content, thumbnail , acf_meet} = meet
    const { info, status } = acf_meet

    // const img = getImage(thumbnail.gatsbyImageData)
    // const contentRef = React.useRef()
    return (
        <>
            <SEO
                subtitle={title}
                description={content}
                image={thumbnail && `${thumbnail.node.localFile.childImageSharp.fluid.src}`}
            />
            <EmbedLawson/>
            <EmbedTicket/>
            <Spacer.Header />
            <Section>
                <SectionHeader
                    title="Meet"
                    text="チケット情報"
                    isBorder={false}
                />
                <Router basepath="/member/meet">
                    <PrivateRoute path="/:id" component={Content} {...meet}/>
                </Router>
                <SectionMoreLink
                    title="More"
                    text="チケット情報一覧"
                    to="/meet/"
                    isBorder={false}
                />
            </Section>
        </>
    )
}

export const pagerQuery = graphql`
    fragment Meet on WpMeet {
        databaseId
        slug
        date(formatString: "YYYY.MM.DD")
        title
        content
        uri
        acf_meet {
            status
            info
        }
    }
    query MeetBySlug($id: String!){
        meet : wpMeet( id: { eq: $id }){
            ...Meet
        }
    }
`
export default MeetPage